<script setup>

import { ref } from "vue"
import { useEARequestStore } from "@/store/ea-request"

const eaRequestStore = useEARequestStore()

const eaRequests = ref([])
const loading = ref(true)
const filter = ref(null)
const status = ref('pending')
const totalRecords = ref(0)
const optionStatus = [
	{ name: "All", value: '' },
	{ name: "Pending", value: 'pending' },
	{ name: "Accept", value: 'accepted' },
	{ name: "Reject", value: 'rejected' }
]

const clearFilter = () => {
  filter.value = null
  search()
}

const search = (e = {}) => {
  loading.value = true

  const page = (e.page || 0) + 1
  eaRequestStore.index({ filter: filter.value, page, status: status.value }).then((res) => {
    eaRequests.value = res.data.ea_requests.data
    totalRecords.value = res.data.ea_requests.total

    loading.value = false
  })
}

search()

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Exnesss</h5>
        <DataTable 
          class="p-datatable-gridlines" 
          dataKey="id" 
          filterDisplay="row" 
          :lazy="true"
          :loading="loading" 
          :paginator="true" 
          responsiveLayout="scroll"
          :rowHover="true" 
          :rows="10" 
          :totalRecords="totalRecords"
          :value="eaRequests" 
          @page="search($event)"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                  <InputText v-model="filter" placeholder="Client UID, Client Account" />
                  <Dropdown v-model="status" optionLabel="name" optionValue="value" :options="optionStatus" />
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
            </div>
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column field="uid" header="UID">
            <template #body="{data}">
              <router-link :to="`/members/${data.member.id}`">
                <span style="color: black !important">{{ data.member.uid }}</span>
                <div v-if="data.member.uhas_uid">
                  <small class="text-primary">Uhas: {{ data.member.uhas_uid }}</small>
                </div>
              </router-link>
            </template>
          </Column>

          <Column field="name" header="Name" style="min-width:12rem">
            <template #body="{data}">
              <img :alt="data.member.name" :src="data.member.avatar" width="32" style="vertical-align: middle" />
              <span style="margin-left: .5em; vertical-align: middle" class="image-text">{{ (data.member.first_name || '') + ' ' + (data.member.last_name || '') }}</span>
            </template>
          </Column>

          <Column header="Account">
            <template #body="{data}">
              <Chip v-if="data.provider" :label="data.provider" />
              {{ data.account_ea }}
            </template>
          </Column>

          <Column field="status" header="Status">
            <template #body="{data}">
              <Chip v-if="data.status == 'pending'" label="Pending" class="custom-chip-warning" />
              <Chip v-else-if="data.status == 'accepted'" label="Accepted" class="custom-chip-success" />
              <Chip v-else-if="data.status == 'rejected'" label="Rejected" class="custom-chip-danger" />
            </template>
          </Column>

          <Column field="note" header="Note" />

          <Column header="Options">
            <template #body="{data}">
              <router-link :to="`/ea-requests/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2 mb-2" />
              </router-link>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>
